<template>
  <div class="home">
    <div class="homebg"></div>
    <!-- <img class="homebg" src="../../assets/img/banner.png" alt="" > -->
    <div class="navbar">
      <div>
        <img src="../../assets/img/ico产品服务.png" alt="">
        <span>产品服务</span>
      </div>
      <div>
        <img src="../../assets/img/ico行业服务.png" alt="">
        <span>行业服务</span>
      </div>
      <div>
        <img src="../../assets/img/ico生态服务.png" alt="">
        <span>生态服务</span>
      </div>
      <div>
        <img src="../../assets/img/ico移动信息化服务.png" alt="">
        <span>移动信息化服务</span>
      </div>
      <div>
        <img src="../../assets/img/ico专业平台搭建服务.png" alt="">
        <span>专业平台搭建服务</span>
      </div>
      <div>
        <img src="../../assets/img/ico风险咨询服务.png" alt="">
        <span>风险咨询服务</span>
      </div>
    </div>
    <div class="advantage">
      <div><span>仁信优势</span></div>
      <span>资质齐备、专家团队、综合运营</span>
      <img src="../../assets/img/优势图片.png" alt="">
    </div>
    <div class="news">
      <div class="title"><span>新闻中心</span></div>
      <span class="title2">行业一线、监管自律、每日新气象</span>
      <div class="main">
        <div class="tab" v-for="item in newsList" :key="item.uuid">
          <span class="span1">{{item.title}}</span>
          <span class="span2">{{item.createTime}}</span>
          <span class="span3" v-html="item.content"></span>
          <button @click="goNews(item.uuid)">查看详情</button>
        </div>
      </div>
      <div class="more" @click="goNews()">更多资讯&nbsp;&nbsp;<img src="../../assets/img/ico箭头.png" alt=""></div>
    </div>
    <div class="partner">
      <div class="title"><span>合作伙伴</span></div>
      <span class="title2">国内多家保险公司(财产险)，共同见证我们的成长</span>
      <div class="main">
        <div>
          <img v-for="item in partnerList" :key="item.id" :src="partnerImg(item.picPath)" alt="">
        </div>
      </div>
    </div>
    <div class="about">
      <div class="top">
        <span>联系我们</span>
        <img src="../../assets/img/LOGO-反白.png" alt="">
      </div>
      <div class="bottom">
        <span>电话号码：021-56482009</span>
        <!-- <span>传真号码：021-51087550</span> -->
        <span>公司地址：上海市宝山区沪太路3100号2号楼D区东侧四层D429</span>
      </div>
      <div class="bottom">
        <span>邮箱地址：shrxbxjjy@hotmail.com</span>
        <span>邮政编码：200444</span>
        <!-- <span></span> -->
      </div>
    </div>
    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有 <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a> </span>
    </div>
  </div>
</template>

<script>
import { article } from "@/api/home";
export default {
  data() {
    return {
      newsList: [],
      partnerList: [],
    }
  },
  created() {
    this.refresh()
  },
  methods: {
    refresh() {
      article({ page: 1, size: 3, sort: 'id,desc', sectionCode: '1', deleteFlag: '0' }).then(res => {
        this.newsList = res.content
      })
      article({ page: 1, size: 100, sort: 'id,desc', sectionCode: '2', deleteFlag: '0' }).then(res => {
        this.partnerList = res.content
      })
    },
    partnerImg(url) {
      return `/official/api/file/preview?source=${url}`;
    },
    goNews(uuid) {
      if (uuid) {
        this.$router.push({ path: '/news/details', query: { id: uuid } })
      } else {
        this.$router.push({ path: '/news' })
      }

    }
  }
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  .homebg {
    height: 560px;
    background: url("../../assets/img/banner.png") no-repeat center;
  }
  .navbar {
    width: 1180px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 0px 28px 2px rgba(216, 224, 233, 0.6);
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 550px;
    transform: translate(-50%, -50%);
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 14px;
      }
      span {
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .advantage {
    background-color: #f4f5f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 140px;
    overflow: hidden;
    div {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 30px;
      border-bottom: solid 6px #ec6941;
      span {
        z-index: 1;
        font-size: 2rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
      }
    }
    span {
      font-size: 1.25rem;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #999999;
    }
    img {
      height: 510px;
    }
  }
  .news {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    .title {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 30px;
      border-bottom: solid 6px #ec6941;
      span {
        z-index: 1;
        font-size: 2rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
      }
    }
    .title2 {
      font-size: 1.25rem;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #999999;
    }
    .main {
      display: flex;
      margin-top: 70px;
      .tab {
        width: 380px;
        height: 288px;
        background: #ffffff;
        box-shadow: 0px 8px 18px 2px rgba(136, 161, 188, 0.18);
        border-radius: 8px;
        padding: 40px 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 10px;
        transition: all 0.5s;
        .span1 {
          font-size: 1.25rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .span2 {
          font-size: 1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .span3 {
          font-size: 1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 28px;

          display: block;
          width: 100%;
          height: 56px;

          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          /deep/ img {
            display: none;
          }
          /deep/ p,
          /deep/ h2,
          /deep/ strong {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }

        button {
          width: 96px;
          height: 40px;
          border: 1px solid #dddddd;
          border-radius: 20px;
          font-size: 0.875rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          background-color: #ffffff;
          cursor: pointer;
          transition: all 0.5s;
        }
        button:hover {
          border: 1px solid #2e4e9a;
          color: #2e4e9a;
          transition: all 0.5s;
        }
      }
      .tab:hover {
        transition: all 0.5s;
        box-shadow: 0px 6px 30px 0px rgba(115, 139, 165, 0.4);
      }
    }
    .more {
      width: 160px;
      height: 48px;
      border: 1px solid #2e4e9a;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2e4e9a;
      margin: 60px 0;
      cursor: pointer;
    }
  }
  .partner {
    background: #f4f5f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    .title {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 30px;
      border-bottom: solid 6px #ec6941;
      span {
        z-index: 1;
        font-size: 2rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
      }
    }
    .title2 {
      font-size: 1.25rem;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #999999;
    }
    .main {
      margin: 70px 0 90px;
      div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 1200px;
        margin-bottom: 40px;
        img {
          margin: 20px 10px;
          width: 180px;
          height: 60px;
        }
      }
    }
  }
  .about {
    background: url("../../assets/img/bg-footer.png") no-repeat center;
    height: 240px;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      width: 1178px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 60px;
      span {
        font-size: 1.5rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .bottom {
      display: flex;
      width: 1178px;
      margin-bottom: 18px;
      span {
        flex: 1;
        font-size: 0.875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .foot {
    height: 64px;
    background: #393d41;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b1a9a9;
  }
}
</style>
